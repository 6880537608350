import { message } from 'antd';
import './style.css'
import { isMobile } from 'react-device-detect';

const HomePage = () => {

    const copiedHandle = () => {
        navigator.clipboard.writeText("N/A")
        message.success('Copied!!!');
    }

    return (
        <>
            {!isMobile ? (
                <div className="wrap-page">
                    <div className="logo">
                        <img src="./images/logo3.png" alt="" />
                    </div>
                    <div className="social">
                        <div className="social-x" onClick={() => window.open('https://twitter.com/uniinuonsol')}>
                            <img src="./images/x.png" alt="" />
                        </div>
                        <div className="social-tele" onClick={() => window.open('https://t.me/uniinuonsol')}>
                            <img src="./images/tele.png" alt="" />
                        </div>
                    </div>
                    <div className="img-left1">
                        <img src="./images/left1.svg" alt="" />
                    </div>
                    <div className="img-left2">
                        <img src="./images/left2.svg" alt="" />
                    </div>
                    <div className="img-right1">
                        <img src="./images/right1.svg" alt="" />
                    </div>
                    <div className="img-right2">
                        <img src="./images/right22.svg" alt="" />
                    </div>
                    <div className="tokenomics">
                        <div className="box-token">
                            <div className="text">
                                UNI TOKENOMICS
                            </div>
                            <div className="address">

                                <input disabled value={'TBA'} className='input-add' type="text" />
                                <button onClick={copiedHandle} className='copy'>Copy</button>


                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="wrap-page-mo">
                    <div className="logo-mo">
                        <img src="./images/logo-mo.svg" alt="" />
                    </div>
                    <div className="social">
                        <div className="social-x" onClick={() => window.open('https://twitter.com/uniinuonsol')}>
                            <img src="./images/x.png" alt="" />
                        </div>
                        <div className="social-tele" onClick={() => window.open('https://t.me/uniinuonsol')}>
                            <img src="./images/tele.png" alt="" />
                        </div>
                    </div>
                    <div className="list-img">
                        <div className="tokenomics-mo">
                            <div className="box-token">
                                <div className="text">
                                    UNI TOKENOMICS
                                </div>
                                <div className="address">
                                    <input disabled value={'TBA'} className='input-add' type="text" />
                                    <button onClick={copiedHandle} className='copy'>Copy</button>
                                </div>
                            </div>
                        </div>
                        <div className="img1">
                            <img src="./images/left1.svg" alt="" />
                        </div>
                        <div className="img1">
                            <img src="./images/left2.svg" alt="" />
                        </div>
                        <div className="img1">
                            <img src="./images/right1.svg" alt="" />
                        </div>
                        <div className="img1">
                            <img src="./images/right22.svg" alt="" />
                        </div>

                    </div>

                </div>
            )}

        </>

    )
}

export default HomePage